<template>
  <base-layout-one page-title="More" content-class="background-white">
    <ion-grid>
      <ion-row>
        <!-- Reporting -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/reporting">
            Reporting
          </ion-button>
        </ion-col>

        <!-- Affiliates -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/affiliates">
            Affiliates
          </ion-button>
        </ion-col>

        <!-- Employers -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/employers">
            Employers
          </ion-button>
        </ion-col>

        <!-- Financials -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/financials">
            Financials
          </ion-button>
        </ion-col>

        <!-- Inventory -->
        <ion-col size-lg="4" size="6">
          <ion-button class="regular-button" expand="block" router-link="/inventories">
            Inventory
          </ion-button>
        </ion-col>

        <!-- Properties -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/properties">
            Properties
          </ion-button>
        </ion-col>

        <!-- Resources -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/resources">
            Resources
          </ion-button>
        </ion-col>

        <!-- Room Types -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/room_types">
            Room Types
          </ion-button>
        </ion-col>

        <!-- Rooms -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/rooms">
            Rooms
          </ion-button>
        </ion-col>

        <!-- Purchasables -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/purchasables">
            Purchasables
          </ion-button>
        </ion-col>

        <!-- Schedule -->
        <ion-col size-lg="4" size="6">
          <ion-button class="regular-button" expand="block" href="https://schedule.boram.live" target="_blank">
            Schedule
          </ion-button>
        </ion-col>

        <!-- Shifts -->
        <ion-col size-lg="4" size="6" v-if="authUser.role === 'admin' || authUser.role === 'manager'">
          <ion-button class="regular-button" expand="block" router-link="/shifts">
            Shifts
          </ion-button>
        </ion-col>

        <!-- Settings -->
        <ion-col size-lg="4" size="6">
          <ion-button class="regular-button" expand="block" router-link="/settings">
            Settings
          </ion-button>
        </ion-col>

        <!-- Version -->
        <ion-col class="ion-text-center" size="12">
          <p class="small">v. {{ $version }}</p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout-one>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/vue";
import { mapGetters } from "vuex";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
  },

  computed: {
    ...mapGetters(["authUser", "version"]),
  },

  data() {
    return {};
  },

  methods: {},
};
</script>